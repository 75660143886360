// 覆盖antd-mobile默认样式

// 默认按钮颜色调整
.am-button-primary {
  background-color: #0099ff;
}
.am-button-ghost {
  overflow: visible;
}

// 弹窗
.am-modal {
  .am-modal-propmt-content {
    .am-modal-input input {
      padding-left: 5px;
    }
  }
}

/*咨询导航栏右侧弹出菜单*/
.am-popover-placement-left {
  top: 6px !important;
  position: fixed !important;

  .am-popover-arrow {
    top: 12px !important;
  }
}

.am-toast {
  > span {
    max-width: 35%;
  }
  .am-toast-text-info {
    font-size: 13px;
    line-height: 1.4;
  }
}

.Custom-List {
  .am-list-header {
    padding: 8px 12px;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #999;
    width: 100%;
    box-sizing: border-box;
  }

  .am-list-item {
    .am-list-line {
      .am-list-content {
        flex: initial;
      }
      .am-list-extra {
        flex: 1 1 auto;
        width: 0;
        margin-left: 12px;
      }
    }
  }
  .am-checkbox-item {
    .am-list-line {
      .am-list-content {
        flex: 1 1;
      }
    }
  }
}
