.appdownload-bar{
  width: 100vw;
  padding: 10px 20px 10px;
  border-bottom: 1px #f9f9f9 solid;
  position: relative;
  background-color: #dcecfb;
  z-index: 100;

  .bar-main{
    width: 100%;
    display: flex;
    align-items: center;
    // background-color: rgba($color: #0099ff, $alpha: 0.1);

    .ddhy-logo{
      img {
        max-width: 36px;
        max-height: 36px;
      }
    }
    .download-text{
      flex: 1 auto;
      margin-left: 10px;

      .app-name{
        font-size: 16px;
        font-weight: bold;
        color: #0099ff;
      }
      .app-desc{
        font-size: 14px;
        color: #333;
        margin-top: 5px;
      }
    }
    .download-btn{
      background-color: #09f;
      border-radius: 15px;
      padding: 5px 10px;
      color: #fff;
    }
  }


}
