$primary_color: #0099ff;

// 只显示两行文本，超出隐藏
.Text-Line__1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

// 只显示两行文本，超出隐藏
.Text-Line__2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.Text-Line__3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.Text-Line__4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.Rich-Text {
  word-break: break-all;
  line-height: 1.5;

  img {
    max-width: 100%;
    height: auto;
  }
}
.Pre-Text {
  word-break: break-all;
  white-space: pre-line;
  line-height: 1.5;
}

.Fixed {
  &Top {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 9;
    top: 0;
  }
  &Bottom {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 9;
    bottom: 0;
  }
}

table {
  line-height: 1.4;
  width: 100%;
  border-collapse: collapse;

  td {
    border: solid 1px #ccc;
    padding: 5px;
  }
}
