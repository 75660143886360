.loading_main{
  width: 100vw;
  height: 100vh;
}

// 加载动画
.loading-animation{
  width:200px;
  height:200px;
  text-align: center;
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;

  h2{
    color: #333;
    margin: 0;
    margin-top: 10px;
    font: 1em verdana;
    text-transform: uppercase;
  }

  span{
    display: inline-block;
    vertical-align: middle;
    width: .6em;
    height: .6em;
    margin: .18em;
    background: #007DB6;
    border-radius: .6em;
    animation: loading 1s infinite alternate;
  }

  span:nth-of-type(2) {
      background: #008FB2;
      animation-delay: 0.2s;
  }

  span:nth-of-type(3) {
      background: #009B9E;
      animation-delay: 0.4s;
  }

  span:nth-of-type(4) {
      background: #00A77D;
      animation-delay: 0.6s;
  }

  span:nth-of-type(5) {
      background: #00B247;
      animation-delay: 0.8s;
  }

  span:nth-of-type(6) {
      background: #5AB027;
      animation-delay: 1.0s;
  }

  span:nth-of-type(7) {
      background: #A0B61E;
      animation-delay: 1.2s;
  }
}

// 品牌标语
.ddhy-slogan{
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100vw;
  height: 50px;
  text-align: center;

  .logo{
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-img{
      height: 30px;
    }
    .logo-txt{
      margin-left: 5px;
      font-size: 15px;
      font-weight: 800;
    }
  }

  .slogan-txt{
    font-size: 12px;
    color: #808080;
    margin-top: 10px;
  }
}


@keyframes loading {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

